<script setup>
    import { ref, onMounted } from 'vue';
    import { useLoginStore } from '@/lib/stores/login-store';
    import LoginWrapper from './LoginWrapper.vue';

    const store = useLoginStore();

    const props = defineProps({
        error: String
    });

    const email = ref(null);
    const phone = ref(null);
    const landcode = ref(null);

    onMounted(() => {
        store.errorText = props.error ? props.error : null;
    });

    const onSendCode = (toggleStep) => {
        var form = document.querySelector('#login-form');
        var valid = form.reportValidity();
        
        if (valid) 
        {
            if (toggleStep)
                store.toggleLoginStep();

            var token = document.querySelector('input[name="__RequestVerificationToken"]').value
            store.sendCode(email?.value?.value, landcode?.value?.value + phone?.value?.value, token);
        }
    };

    let codeArr = ["", "", "", "", "", ""]; 
    const next = (e, i) => {
        codeArr[i] = e.target.value;
        
        if (e.inputType === "insertText")
            e.target?.nextSibling?.focus();

        store.code = codeArr.toString().replaceAll(",", "");
    }
</script>

<template>
    <div class="login pinned-bleed">
        <div class="container">
            <div class="row">
                <div class="text--center mb-2" v-if="store.errorText">{{store.errorText}}</div>
                <LoginWrapper title="Logg inn hvis du ønsker å lese denne artikkelen" subTitle="Abonnenter og medlemmer av NIL, NAL, NLA, Afag og Arkitektbedriftene har tilgang. Logg inn med e-post." label="E-post" v-if="!store.isLoginPhone && !store.isLoginNextStep">
                    <div class="fakta text--left extra-p">
                        <input type="email" ref="email" required />
                    </div>
                    <div class="fakta extra-p">
                        <button type="submit" @click.prevent="onSendCode(true);" class="btn btn--primary w-100 mt-4">Neste</button>
                    </div>
                    <div class="fakta mt-4">
                        <a href="/loginphone" @click.prevent="store.toggleLoginMethod" class="focusable">Logg inn med telefonnummer</a>
                    </div>
                </LoginWrapper>
                <LoginWrapper title="Logg inn med telefonnummer" subTitle="Abonnenter og medlemmer av NIL, NAL, NLA, Afag og Arkitektbedriftene har tilgang. Fyll inn telefonnummer under." label="Telefonnummer" v-else-if="!store.isLoginNextStep">
                    <div class="fakta text--left extra-p d-flex justify-content-between">
                        <span class="prelandcode">+</span>
                        <input type="number" class="landcode text--center" value="47" min="10" max="99" ref="landcode" required />
                        <input type="number" min="10000000" ref="phone" required />
                    </div>
                    <div class="fakta extra-p">
                        <button type="submit" @click.prevent="onSendCode(true);" class="btn btn--primary w-100 mt-4">Neste</button>
                    </div>
                    <div class="fakta mt-4">
                        <a href="/loginemail" @click.prevent="store.toggleLoginMethod" class="focusable">Logg inn med e-post</a>
                    </div>
                </LoginWrapper>
                <LoginWrapper title="Bekreft kode" subTitle="Hvis du er abonnent, har vi sendt deg en engangskode. Skriv det inn her." label="Kode" v-else>
                    <div class="fakta text--left extra-p d-flex justify-content-between text--center">
                        <input type="number" name="input1" class="code" @input="(e) => next(e, 0)" min="0" max="9" :autofocus="'autofocus'" />
                        <input type="number" name="input2" class="code" @input="(e) => next(e, 1)" min="0" max="9" />
                        <input type="number" name="input3" class="code" @input="(e) => next(e, 2)" min="0" max="9" />
                        <input type="number" name="input4" class="code" @input="(e) => next(e, 3)" min="0" max="9" />
                        <input type="number" name="input5" class="code" @input="(e) => next(e, 4)" min="0" max="9" />
                        <input type="number" name="input6" class="code" @input="(e) => next(e, 5)" min="0" max="9" />
                    </div>
                    <div class="fakta extra-p">
                        <button type="submit" class="btn btn--primary w-100 mt-4">Logg inn</button>
                    </div>
                    <div class="fakta mt-4">
                        <a href="/back" @click.prevent="store.toggleLoginStep" class="focusable">Tilbake</a>
                    </div>
                    <div class="fakta mt-2">
                        <a href="/sendagain" @click.prevent="onSendCode(false);" class="focusable">Send kode på nytt</a>
                    </div>
                </LoginWrapper>
                <div class="col-12">
                    <div class="separator" v-if="!store.isLoginNextStep"></div>
                </div>
                <div class="col-12 col-md-6 offset-md-3 text--center" v-if="!store.isLoginNextStep">
                    <h2 class="h2-large-classic text--medium mb-3">Bli abonnent fra kr 1280 / år</h2>
                    <div class="fakta">
                        Dersom du er medlem av NIL, NAL, NLA, Afag og Arkitektbedriftene har du allerede tilgang til Arkitektur. Logg inn med e-post over
                    </div>
                    <div class="fakta extra-p">
                        <a href="https://www4.solidus.no/SolidusUser/nettbutikk/norske_arkitekters_landsforbund/abonnement" class="btn btn--secondary w-100 mt-5">Bli abonnent</a>
                    </div>
                </div>
                <input type="hidden" name="email" :value="store.email" />
                <input type="hidden" name="phone" :value="store.phone" />
                <input type="hidden" name="code" :value="store.code" />
            </div>
        </div>
    </div>
</template>  
