<script setup>
    import { useGlobalStore } from '@/lib/stores/global-store';
    import Logo from '../Logo/Logo.vue';
    import { onMounted, ref } from 'vue';

    const store = useGlobalStore();

    const props = defineProps({
        primaryLinks: Array,
        searchLink: String
    });

    const isFrontpage = ref(false);

    const onMenuButtonClick = () => {
        store.toggleMenu(true);
    };

    onMounted(() => {
        let lastScrollTop;
        let header = document.getElementsByClassName('header')[0];
        let darkMode = document.body.classList.contains('theme--dark');
        let landing = document.body.classList.contains('page--landing');
        let opinionList = document.body.classList.contains('page--opinionlanding');
        let opinion = document.body.classList.contains('page--opinion');
        let projectLandingPage = document.body.classList.contains('page--projectlanding');
        let tema = document.body.classList.contains('theme');
        let alternative = document.body.classList.contains('alternative');
        let portrait = document.body.classList.contains('portrait');

        if (darkMode) {
            header.classList.add('theme--dark');
        }
        if (tema && alternative) {
            header.classList.add('theme-alternative');
        }
        if (landing) {
            header.classList.add('theme--frontpage');
            isFrontpage.value = true;
        }
        if (opinion || opinionList) {
            header.classList.add('theme--opinion');
        }
        if (projectLandingPage) {
            header.classList.add('theme--project-landing-page');
        }
        if (portrait) {
            header.classList.add('theme--portrait');
            var menuItems = document.getElementsByClassName('primary-menu');
            [].forEach.call(menuItems, function (el) {
                el.classList.add('d-none');
            });
        }

        window.addEventListener('scroll', function () {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            // Scrolling down
            if (scrollTop > lastScrollTop) {
                header.classList.add('navbar--scroll-down');
                header.classList.remove('navbar--scroll-up');
                header.classList.remove('navbar--scroll-top');
            }
            // Scrolling up
            else {
                header.classList.remove('navbar--scroll-down');
                header.classList.add('navbar--scroll-up');
                header.classList.remove('navbar--scroll-top');
            }
            // Scrolling all the way up
            if (document.documentElement.scrollTop == 0) {
                header.classList.remove('navbar--scroll-down');
                header.classList.remove('navbar--scroll-up');
                header.classList.add('navbar--scroll-top');
            }
            lastScrollTop = scrollTop;
        });
    });
</script>

<template>
    <header class="header">
        <div class="container">
            <div class="header__inner">
                <a class="header__logo big" href="/" v-if="isFrontpage">
                    <Logo />
                    <span class="sr-only">Til startsiden</span>
                </a>
                <a class="header__logo" href="/" v-else>
                    <Logo />
                    <span class="sr-only">Til startsiden</span>
                </a>
                <nav class="header__nav d-flex justify-content-between">
                    <h2 class="sr-only">Navigasjon</h2>
                    <ul class="header__menu me-5">
                        <li
                            v-for="link in props.primaryLinks"
                            class="header__menu-item primary-menu"
                            :key="link.title"
                        >
                            <a :class="{ active: link.isActive, focusable: true}" :href="link.href">
                                {{ link.title }}
                            </a>
                        </li>
                    </ul>
                    <div class="d-flex">
                        <div class="header__menu-item header__menu-item--button align-self-center">
                            <a :href="searchLink">
                                <button tabindex="-1">
                                    <span class="icon icon--search"></span>
                                    <span class="sr-only">Søk</span>
                                </button>
                            </a>
                        </div>
                        <div class="header__menu-item header__menu-item--button align-self-center hamburger">
                            <a href="/menu" @click.prevent="onMenuButtonClick">
                                <button tabindex="-1">
                                    <span class="icon icon--menu"></span>
                                    <span class="icon icon--menu-hover"></span>
                                    <span class="sr-only">Meny</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>  
