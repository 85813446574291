<template>
    <div class="facts-block p-block">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-9 col-xl-6 offset-md-3 offset-xl-3">
                    <div class="fakta facts-block__type">
                        <span>{{subTitle}}</span>
                    </div>
                    <h2 class="h4-grotesque facts-block__title">{{title}}</h2>

                    <ul class="facts-block__list">
                        <li v-for="index in take"
                                    :key="index">
                            {{textItems[index - 1].text}}
                        </li>
                    </ul>

                    <a class="fakta underline pointer" v-if="props.textItems.length > take" v-on:click="increaseTake()">Last inn mer +</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, computed, ref } from 'vue';

    const props = defineProps({
        title: String,
        subTitle: String,
        textItems: Array
    });
    
    const take = ref(0);

    const increaseTake = () => {
        take.value = props.textItems.length > take + 2 ? take + 2 : props.textItems.length;
    };

    onMounted(() => {
        take.value = props.textItems.length < 2 ? props.textItems.length : 2;
    });
</script>
