<script setup>
    import { computed, onMounted } from 'vue';
    import { useGlobalStore } from '@/lib/stores/global-store';

    const initialAmount = 6;

    const store = useGlobalStore();

    const props = defineProps({
        categoryLinks: Array,
        pageTitle: String,
    });

    const isFilterOpen = computed(() => store.isFilterOpen);

    const onCloseButtonClick = () => {
        store.toggleFilterShowMore(true);
        store.toggleFilter();
    };

    const onShowMoreClick = () => {
        store.toggleFilterShowMore(true);
    };

    const showCategories = computed(() => {
        if (isFilterOpen.value) {
            return 'show';
        } else {
            return 'hide';
        }
    });

    const categoryFullHeight = computed(() => {
        if (isFilterOpen.value) {
            return 'category-full-height';
        }
    });

    const visibleCategories = computed(() => {
        var take = store.isFilterShowMore ? props.categoryLinks.length : initialAmount;
        return props.categoryLinks.slice(0, take);
    });

    onMounted(() => {
        store.toggleFilterShowMore(props.categoryLinks.length <= initialAmount);
    });

</script>

<template>
    <nav class="category__nav" :class="categoryFullHeight">
        <div class="category__header">
            <h1 v-if="isFilterOpen" class="h1-display">Filter</h1>
            <h1 v-else class="h1-display">{{ props.pageTitle }}</h1>
            <div class="category__filter-button">
                <button class="category__close-button" @click.prevent="onCloseButtonClick">
                    <span v-if="isFilterOpen" class="fakta">Lukk</span>
                    <span v-else class="fakta">Filter</span>
                    <span class="sr-only">Lukk filteret</span>
                </button>
            </div>
        </div>
        <ul class="category__menu" :class="showCategories">
            <li v-for="link in visibleCategories" class="category__menu-item" :key="link.title">
                <a class="btn btn--secondary" :class="{ active: link.isActive }" :href="link.href">
                    {{ link.title }}
                </a>
            </li>
            <li class="category__menu-item" key="showmore" v-if="!store.isFilterShowMore">
                <a class="btn btn--tertiary" href="/filtershowmore" @click.prevent="onShowMoreClick">
                    Flere ...
                </a>
            </li>
        </ul>
    </nav>
</template>

