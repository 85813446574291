import { defineStore } from 'pinia';

export const useLoginStore = defineStore('login', {
    state: () => ({ 
        isLoginPhone: false,
        isLoginNextStep: false,
        errorText: null,
        email: null,
        phone: null,
        code: null,
    }),
    actions: {
        toggleLoginMethod() {
            this.errorText = null;
            this.isLoginPhone = !this.isLoginPhone;
        },
        toggleLoginStep() {
            this.errorText = null;
            this.isLoginNextStep = !this.isLoginNextStep;
        },
        sendCode(email, phone, token) {
            if (this.isLoginPhone && phone) 
            {
                this.phone = phone;
                this.email = "";
            }
            else if (email)
            {
                this.email = email;
                this.phone = "";
            }

            const requestOptions = {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "RequestVerificationToken": token
                }
            };

            fetch("/umbraco/surface/loginform/login?email=" + this.email + "&phone=" + this.phone, requestOptions)
                .then(response => {
                    if (!response.ok) 
                    {
                        this.errorText = response.status === 429 ? "Du har prøvd for mange ganger. Vent litt og prøv igjen." : "Noe gikk galt. Prøv igjen."
                        this.isLoginNextStep = false;
                    }
                });
        }
    }
});
