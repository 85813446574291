<script setup>
    import { onMounted, ref } from 'vue';

    const props = defineProps({
        formId: String,
        sitekey: String,
        url: String
    });

    const missingCaptcha = ref(false);

    function captchaCallback(token) {
        missingCaptcha.value = false;
    }

    onMounted(() => {
        window.captchaCallback = captchaCallback;

        const hcaptcha = document.getElementById("hcaptcha");
        hcaptcha.setAttribute("data-callback", "captchaCallback");

        const captchaScript = document.createElement('script');
        captchaScript.setAttribute('src', props.url);
        captchaScript.defer = true;
        document.head.appendChild(captchaScript);

        const form = document.getElementById(props.formId);
        form.addEventListener("submit", function(event) {
            const hcaptchaValue = document.querySelector('[name="h-captcha-response"]').value;
            if (hcaptchaValue === "") {
                missingCaptcha.value = true;
                event.preventDefault();
            }
        });
    });
</script>

<template>
    <div>
        <div id="hcaptcha" class="h-captcha" :data-sitekey="props.sitekey">
        </div>
        <div v-if="missingCaptcha">
            <span>CAPTCHA mislyktes</span>
        </div>
    </div>
</template>