<script setup>
    import { DesktopAds, MobileAds, Ads } from '@/lib/constants/constants';
    import { onBeforeMount, reactive, onMounted } from 'vue';

    const props = defineProps({
        adType: {
            type: String,
            required: true,
            validator: (value) =>
                Ads.find((obj) => {
                    return obj.name === value;
                }) != undefined
        }
    });

    const state = reactive({
        desktopAd: {},
        mobileAd: {}
    });

    onBeforeMount(() => {
        state.desktopAd = DesktopAds.find((obj) => {
            return obj.name === props.adType;
        });
        state.mobileAd = MobileAds.find((obj) => {
            return obj.name === props.adType;
        });
    });

    onMounted(() => {
        init();

        addEventListener('resize', (event) => {
            init();
        });
    });

    const init = () => {
        setWrapperHeight(state.desktopAd);
        setWrapperHeight(state.mobileAd);
    };

    const setWrapperHeight = (adDefinitions) => {
        var heightPercentageOfWidth = adDefinitions.heightPercentageOfWidth;
        var tagId = adDefinitions.tagId;

        // Set wrapper min height to avoid lagging when the iframe ad is rendered and finally takes up space
        const wrapper = document.getElementById('wrapper-' + tagId);
        if (wrapper != null) {
            var wrapperMinHeight = wrapper.offsetWidth * heightPercentageOfWidth - 10;
            wrapper.style.minHeight = wrapperMinHeight + 'px';
        }
    };
</script>

<template>
    <div v-if="props.adType != 'A'" class="tag-label tag-label__separator">
        <span class="tag-label__type">ANNONSE</span>
    </div>

    <!-- Mobile -->
    <div
        :id="'wrapper-' + state.mobileAd.tagId"
        class="ad-wrapper-outer mobile"
        :class="props.adType"
    >
        <div
            :id="'adn-' + state.mobileAd.tagId"
            class="ad-wrapper-inner"
            style="display: none"
        ></div>
    </div>

    <!-- Desktop -->
    <div
        :id="'wrapper-' + state.desktopAd.tagId"
        class="ad-wrapper-outer desktop"
        :class="props.adType"
    >
        <div
            :id="'adn-' + state.desktopAd.tagId"
            class="ad-wrapper-inner"
            style="display: none"
        ></div>
    </div>
</template>
