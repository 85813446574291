<template>
    <div class="logo">
        <svg width="1435" height="299" viewBox="0 0 1435 299" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3452_21281)">
        <path d="M175.334 289.908V294.254H106.616V289.908H116.896C124.539 289.908 125.123 286.139 123.07 275.72L103.095 179.543H56.9881L35.5446 275.72C33.1915 286.156 34.0761 289.908 41.7193 289.908H51.9988V294.254H0V289.908H10.2794C17.9227 289.908 22.3282 286.139 24.6636 275.72L86.641 4.85059H96.0358L158.013 275.72C160.366 286.156 164.772 289.908 172.397 289.908H175.334ZM59.0405 170.835H101.326L80.7671 72.3367L59.0405 170.835Z" fill="#231F20"/>
        <path d="M177.703 294.254V289.908H186.514C194.157 289.908 196.793 286.139 196.793 275.72V28.8815C196.793 18.4453 194.157 14.6932 186.514 14.6932H176.234V10.3477H245.554C283.434 10.3477 308.699 33.8203 308.699 74.0817C308.699 109.142 291.078 131.446 265.229 138.688L306.647 275.72C310.469 287.884 318.395 289.908 326.038 289.908H327.808V294.254H258.788V289.908H267.9C275.243 289.908 276.127 286.139 272.889 274.271L236.177 141.306H225.314V275.72C225.314 286.156 227.95 289.908 235.593 289.908H245.873V294.254H177.738H177.703ZM225.278 135.495H244.369C266.096 135.495 280.197 111.743 280.197 74.0817C280.197 36.4207 266.096 14.6932 244.369 14.6932C231.152 14.6932 225.278 19.0387 225.278 28.8815V135.495Z" fill="#231F20"/>
        <path d="M489.601 289.908V294.253H414.708V289.908H424.687C432.029 289.908 436.134 285.562 431.729 274.55L381.216 145.93V275.719C381.216 286.156 383.852 289.908 391.495 289.908H401.775V294.253H332.172V289.908H342.451C350.095 289.908 352.731 286.138 352.731 275.719V28.881C352.731 18.4448 350.095 14.6927 342.451 14.6927H332.172V10.3472H401.775V14.6927H391.495C383.852 14.6927 381.216 18.4623 381.216 28.881V125.634L440.54 28.881C446.714 19.0382 442.309 14.6927 436.134 14.6927H427.022V10.3472H480.773V14.6927H472.546C461.965 14.6927 457.277 18.1656 450.819 28.881L398.537 115.791L466.389 275.702C471.095 286.993 476.084 289.89 483.71 289.89H489.584L489.601 289.908Z" fill="#231F20"/>
        <path d="M492.531 294.255V289.91H502.811C510.454 289.91 513.09 286.14 513.09 275.721V28.8829C513.09 18.4468 510.454 14.6946 502.811 14.6946H492.531V10.3491H562.134V14.6946H551.855C544.212 14.6946 541.575 18.4642 541.575 28.8829V275.721C541.575 286.158 544.212 289.91 551.855 289.91H562.134V294.255H492.531Z" fill="#231F20"/>
        <path d="M560.664 89.1429L587.398 0.208496L593.572 1.95368L593.873 5.14736C594.174 8.04436 596.81 10.3655 599.747 10.3655H684.92C688.741 10.3655 690.493 9.21363 691.961 5.44404L694.014 0.225948L700.188 1.97113L673.756 90.9056L666.997 89.1604L677.276 52.0753C680.213 41.9358 685.203 15.5661 661.424 15.5661H644.687V275.72C644.687 286.156 647.323 289.909 654.966 289.909H665.245V294.254H595.642V289.909H605.922C613.565 289.909 616.201 286.139 616.201 275.72V16.142C587.132 20.4875 578.321 55.5482 567.44 90.8881L560.682 89.1429H560.664Z" fill="#231F20"/>
        <path d="M698.744 294.254V289.908H709.024C716.667 289.908 719.303 286.139 719.303 275.72V28.8815C719.303 18.4453 716.667 14.6932 709.024 14.6932H701.681V10.3477H815.64L821.213 81.6209L815.339 83.3661L812.101 63.3837C808.863 43.6806 797.416 14.7106 768.347 14.7106C754.246 14.7106 747.788 19.0561 747.788 28.8989V127.973H776.574C788.623 127.973 795.665 118.13 795.665 101.603V91.167H801.539V175.756H795.665V162.423C795.665 145.617 788.623 136.053 776.574 136.053H747.788V275.685C747.788 285.528 753.662 289.873 766.295 289.873C787.155 289.873 805.643 277.134 817.391 242.37L826.503 215.424L833.545 217.169L815.622 294.236H698.727L698.744 294.254Z" fill="#231F20"/>
        <path d="M1012.05 289.908V294.254H938.629V289.908H948.608C955.95 289.908 960.657 285.563 955.649 274.551L893.088 135.495L888.099 143.889V275.703C888.099 286.139 890.735 289.891 898.378 289.891H908.658V294.236H839.055V289.891H849.334C856.977 289.891 859.614 286.121 859.614 275.703V28.8815C859.614 18.4453 856.977 14.6932 849.334 14.6932H839.055V10.3477H908.658V14.6932H898.378C890.735 14.6932 888.099 18.4628 888.099 28.8815V124.483L946.838 28.8815C953.013 19.0387 948.608 14.6932 942.433 14.6932H933.321V10.3477H987.072V14.6932H978.845C968.264 14.6932 963.576 18.1661 957.118 28.8815L910.71 106.525L990.309 275.703C995.6 286.994 1000.01 289.891 1007.63 289.891H1012.04L1012.05 289.908Z" fill="#231F20"/>
        <path d="M981.242 89.1429L1007.98 0.208496L1014.15 1.95368L1014.45 5.14736C1014.75 8.04436 1017.39 10.3655 1020.33 10.3655H1105.5C1109.32 10.3655 1111.07 9.21363 1112.54 5.44404L1114.59 0.225948L1120.77 1.97113L1094.33 90.9056L1087.58 89.1604L1097.85 52.0753C1100.79 41.9358 1105.78 15.5661 1082 15.5661H1065.26V275.72C1065.26 286.156 1067.9 289.909 1075.54 289.909H1085.82V294.254H1016.22V289.909H1026.5C1034.14 289.909 1036.78 286.139 1036.78 275.72V16.142C1007.71 20.4875 998.9 55.5482 988.019 90.8881L981.26 89.1429H981.242Z" fill="#231F20"/>
        <path d="M1122.24 14.6932V10.3477H1188.91V14.6932H1178.63C1170.99 14.6932 1168.35 18.4628 1168.35 28.8815V227.902C1168.35 270.484 1178.63 287.587 1204.18 287.587C1231.5 287.587 1242.36 270.781 1242.36 229.071V28.8815C1242.36 18.4453 1239.72 14.6932 1232.08 14.6932H1221.8V10.3477H1272.31V14.6932H1262.03C1254.39 14.6932 1251.75 18.4628 1251.75 28.8815V232.544C1251.75 279.769 1236.18 298.879 1198 298.879C1156.59 298.879 1139.85 278.6 1139.85 227.902V28.8815C1139.85 18.4453 1137.21 14.6932 1129.57 14.6932H1122.23H1122.24Z" fill="#231F20"/>
        <path d="M1279.09 294.254V289.908H1289.37C1297.01 289.908 1299.64 286.139 1299.64 275.72V28.8815C1299.64 18.4453 1297.01 14.6932 1289.37 14.6932H1279.09V10.3477H1348.41C1386.29 10.3477 1411.55 33.8203 1411.55 74.0817C1411.55 109.142 1393.93 131.446 1368.08 138.688L1409.5 275.72C1413.32 287.884 1421.25 289.908 1428.89 289.908H1435.06V294.254H1361.64V289.908H1370.75C1378.09 289.908 1378.98 286.139 1375.74 274.271L1339.03 141.306H1328.17V275.72C1328.17 286.156 1330.8 289.908 1338.44 289.908H1348.72V294.254H1279.12H1279.09ZM1328.13 135.495H1347.22C1368.95 135.495 1383.05 111.743 1383.05 74.0817C1383.05 36.4207 1368.95 14.6932 1347.22 14.6932C1334 14.6932 1328.13 19.0387 1328.13 28.8815V135.495Z" fill="#231F20"/>
        </g>
        <defs>
        <clipPath id="clip0_3452_21281">
        <rect width="1435" height="298.688" fill="white" transform="translate(0 0.208496)"/>
        </clipPath>
        </defs>
        </svg>
    </div>
</template>
