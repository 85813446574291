<script setup>
    import { computed } from 'vue';
    import { useGlobalStore } from '@/lib/stores/global-store';

    const store = useGlobalStore();

    const props = defineProps({
        primaryLinks: Array,
        secondaryLinks: Array,
        logoutLink: String,
        logoutTitle: String,
        showLogout: Boolean
    });

    const isMenuOpen = computed(() => store.isMenuOpen);

    const onCloseButtonClick = () => {
        store.toggleMenu(false);
    };
</script>

<template>
    <transition name="menu-transition">
        <div class="menu" v-if="isMenuOpen">
            <div class="container">
                <div class="menu__header">
                    <a href="/closemenu" @click.prevent="onCloseButtonClick">
                        <button tabindex="-1" class="menu__close-button">
                            <span class="icon icon--close-32"></span>
                            <span class="sr-only">Lukk menyen</span>
                        </button>
                    </a>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <nav class="menu__main-nav">
                            <ul>
                                <li
                                    v-for="link in props.primaryLinks"
                                    class="menu__item"
                                    :key="link.title"
                                >
                                    <a :class="{ active: link.isActive, focusable: true }" :href="link.href">
                                        {{ link.title }}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="col-md-4">
                        <nav class="menu__secondary-nav">
                            <a :href="props.logoutLink" class="link--login" v-if="props.showLogout">
                                <span class="icon icon--user"></span>
                                {{ props.logoutTitle }}
                            </a>
                            <ul>
                                <li
                                    v-for="link in props.secondaryLinks"
                                    class="menu__item"
                                    :key="link.title"
                                >
                                    <a :class="{ active: link.isActive, focusable: true }" :href="link.href">
                                        {{ link.title }}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
    .menu-transition-enter-active,
    .menu-transition-leave-active {
        transition: opacity 0.3s ease-in;
    }

    .menu-transition-enter-from,
    .menu-transition-leave-to {
        opacity: 0;
    }
</style>
