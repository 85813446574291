export const FilterType = {
    TAG: 'Tag',
    FIRM: 'Firm'
};

export const MapStyle = {
    DARK: 'mapbox://styles/nal-arkitektur/clcrrluzj000x14qzojd8w4m3',
    LIGHT: 'mapbox://styles/nal-arkitektur/clcrkk76v006z14t95jtpeqbi',
    LANDING: 'mapbox://styles/nal-arkitektur/clcrjtut9001914qek2crj8nv',
    DEFAULT: 'mapbox://styles/mapbox/streets-v11'
};

export const Map = {
    ACCESS_TOKEN:
        'pk.eyJ1IjoibmFsLWFya2l0ZWt0dXIiLCJhIjoiY2xjcWU0OHNzMDUycjN3cDB4dGp6aGY0NyJ9.1OUGkMv5TRr_Gi-PvGxdcQ'
};

export const FullbleedDesktopAds = [
    {
        name: 'E',
        tagId: '000000000023298f',
        heightPercentageOfWidth: 0.56
    }
];

export const FullbleedMobileAds = [
    {
        name: 'E',
        tagId: '000000000023299a',
        heightPercentageOfWidth: 1.22
    }
];

export const DesktopAds = [
    {
        name: 'A',
        tagId: '0000000000232991',
        heightPercentageOfWidth: 0.16
    },
    {
        name: 'B',
        tagId: '0000000000232996',
        heightPercentageOfWidth: 0.6
    },
    {
        name: 'C1',
        tagId: '0000000000232997',
        heightPercentageOfWidth: 0.6
    },
    {
        name: 'C2',
        tagId: '0000000000235fde',
        heightPercentageOfWidth: 0.6
    },
    {
        name: 'D',
        tagId: '0000000000232993',
        heightPercentageOfWidth: 0.3
    }
];

export const MobileAds = [
    {
        name: 'A',
        tagId: '000000000023299c',
        heightPercentageOfWidth: 0.43
    },
    {
        name: 'B',
        tagId: '00000000002360ee',
        heightPercentageOfWidth: 1.22
    },
    {
        name: 'C1',
        tagId: '000000000023299d',
        heightPercentageOfWidth: 1.22
    },
    {
        name: 'C2',
        tagId: '0000000000235fed',
        heightPercentageOfWidth: 1.22
    },
    {
        name: 'D',
        tagId: '000000000023299e',
        heightPercentageOfWidth: 1.22
    }
];

export const Ads = FullbleedDesktopAds.concat(FullbleedMobileAds)
    .concat(DesktopAds)
    .concat(MobileAds);
