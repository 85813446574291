<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        title: String,
        projectTitle: String,
        architect: String,
        imageUrl: String,
        alt: String,
        target: String
    });

    var image = ref(null);

    // Control variables
    let mx = 0;
    let my = 0;
    let ix = 0;
    let iy = 0;

    let activated = false;

    const onMouseMove = (e) => {
        mx = e.pageX - e.currentTarget.offsetLeft - 130; 
        my = e.pageY - e.currentTarget.offsetTop - 184;

        if (!activated) 
        {
            ix = innerWidth / 2;
        }
        
        activated = true;
    };

    const onMouseLeave = () => 
    {
        mx = innerWidth / 2;
        my = 0
    }

    window.requestAnimationFrame(render);
    function render() {
        if (activated) 
        {
            ix = lerp(ix, mx, 0.1);
            iy = lerp(iy, determineY(), 0.1);
            
            image.value.style.left = `${ix}px`;
            image.value.style.top = `${iy}px`;
        }
        
        window.requestAnimationFrame(render);
    }

    function determineY() 
    {
        if (my > 0 && my < 132) 
        {
            return my;
        }
        else if (my <= 0) 
        {
            return 1;
        }
        else {
            return 132;
        }
    }

    const lerp = (a, b, n) => {
        return (1 - n) * a + n * b;
    }
</script>

<template>
    <div class="related-project">
        <div class="container">
            <div class="h4-grotesque mb-4">
                {{ props.title }}
            </div>
        </div>
        <a :href="props.target" class="focusable">
            <!-- MOBILE -->
            <div class="container">
                <div class="d-flex d-md-none flex-column mb-4">
                    <h2 class="h1-display mb-2">
                        {{ props.projectTitle }}
                    </h2>
                    <h2 class="h1-display text--muted mb-4">
                        {{ props.architect }}
                    </h2>
                    <div class="ps-5">
                        <img :src="props.imageUrl" :alt="props.alt" class="img-fluid" v-if="props.imageUrl">
                    </div>
                </div>
            </div>
            <!-- TABLET/DESKTOP -->
            <div class="panorama d-none d-md-flex flex-column justify-content-center" @mousemove="onMouseMove" @mouseleave="onMouseLeave">
                <img :src="props.imageUrl" :alt="props.alt" class="img-fluid" v-if="props.imageUrl" ref="image">
                <div>
                    <div class="marquee">
                        <ul class="marquee__content animate">
                            <li>
                                <h2 class="h1-display marquee">
                                    {{ props.projectTitle }}
                                </h2>
                            </li>
                            <li>
                                <span class="icon icon--arrow-right-16px">
                                </span>
                            </li>
                        </ul>
                        <ul aria-hidden="true" class="marquee__content animate">
                            <li>
                                <h2 class="h1-display marquee">
                                    {{ props.projectTitle }}
                                </h2>
                            </li>
                            <li>
                                <span class="icon icon--arrow-right-16px">
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="marquee">
                        <ul class="marquee__content animate--slow">
                            <li>
                                <h2 class="h1-display text--muted">
                                    {{ props.architect }}
                                </h2>
                            </li>
                            <li>
                                <span class="icon icon--arrow-right-16px text--muted">
                                </span>
                            </li>
                        </ul>
                        <ul aria-hidden="true" class="marquee__content animate--slow">
                            <li>
                                <h2 class="h1-display text--muted">
                                    {{ props.architect }}
                                </h2>
                            </li>
                            <li>
                                <span class="icon icon--arrow-right-16px text--muted">
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>
