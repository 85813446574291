import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('global', {
    state: () => ({ 
        isMenuOpen: false, 
        isFilterOpen: false, 
        isFilterShowMore: false, 
        isLoginPhone: false,
        isLoginNextStep: false,
    }),
    // getters: {
    //   doubleCount: (state) => state.count * 2,
    // },
    actions: {
        toggleMenu(forceState = null) {
            this.isMenuOpen = forceState != null ? forceState : !this.isMenuOpen;
        },
        toggleFilter(forceState = null) {
            this.isFilterOpen = forceState != null ? forceState : !this.isFilterOpen;
        },
        toggleFilterShowMore(forceState = null) {
            this.isFilterShowMore = forceState != null ? forceState : !this.isFilterOpen;
        },
        toggleLogin(forceState = null) {
            this.isLoginPhone = forceState != null ? forceState : !this.isLoginPhone;
        },
        toggleLoginStep(forceState = null) {
            this.isLoginNextStep = forceState != null ? forceState : !this.isLoginNextStep;
        },
    }
});
