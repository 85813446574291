<script setup>
    import { FullbleedDesktopAds, FullbleedMobileAds, Ads } from '@/lib/constants/constants';
    import { onBeforeMount, reactive, onMounted } from 'vue';

    const props = defineProps({
        adType: {
            type: String,
            required: true,
            validator: (value) =>
                Ads.find((obj) => {
                    return obj.name === value;
                }) != undefined
        }
    });

    const state = reactive({
        desktopAd: {},
        mobileAd: {},
        iframe: {},
        iframeIsSet: false,
        imageUrl: null,
        isMobile: false
    });

    onBeforeMount(() => {
        state.desktopAd = FullbleedDesktopAds.find((obj) => {
            return obj.name === props.adType;
        });
        state.mobileAd = FullbleedMobileAds.find((obj) => {
            return obj.name === props.adType;
        });
    });

    onMounted(() => {
        //todo: use other event?
        window.addEventListener('message', (ev) => {
            const wrapper = getWrapper();
            const iframe = wrapper.firstChild;
            if (iframe != null) {
                if (state.iframeIsSet === true) return;
                state.iframeIsSet = true;

                iframe.onload = function () {
                    setDataFromIframe(iframe);
                };
            }
        });

        addEventListener('resize', (event) => {
            const wrapper = getWrapper();
            const iframe = wrapper.firstChild;
            if (iframe != null) {
                setDataFromIframe(iframe);
            }
        });
    });

    const getWrapper = () => {
        setIsMobile();
        var wrapperId = state.isMobile
            ? 'adn-' + state.mobileAd.tagId
            : 'adn-' + state.desktopAd.tagId;
        return document.getElementById(wrapperId);
    };

    const setDataFromIframe = (iframe) => {
        state.iframe = iframe;

        setIsMobile();
        setImageHeight();

        var image = getImageElement(iframe);
        var imgSrc = image?.src;
        if (imgSrc != null) {
            state.imageUrl = imgSrc;
        }
    };

    const setIsMobile = () => {
        let width = document.body.clientWidth;
        //todo: get variable
        if (width < 769) {
            state.isMobile = true;
        } else {
            state.isMobile = false;
        }
    };

    const getImageElement = (iframe) => {
        return iframe.contentWindow?.document?.images[0];
    };

    const setImageHeight = () => {
        var adDefinitions = state.isMobile === true ? state.mobileAd : state.desktopAd;
        var heightPercentageOfWidth = adDefinitions.heightPercentageOfWidth;
        const image = document.getElementById('fullbleed-parallax-image');
        if (image != null) {
            var imgHeight = image.offsetWidth * heightPercentageOfWidth;
            image.style.height = imgHeight + 'px';
        }
    };

    const handleAdClick = () => {
        // fetch the link element from the iframe and click it
        // nb. this way we preserve the statistic updates in Adnuntius
        var image = getImageElement(state.iframe);
        var link = image?.parentElement;
        if (link != null) {
            link.click();
        }
    };
</script>

<template>
    <div class="fullbleed-wrapper desktop">
        <div :id="'adn-' + state.desktopAd.tagId" style="display: none"></div>
    </div>
    <div class="fullbleed-wrapper mobile">
        <div :id="'adn-' + state.mobileAd.tagId" style="display: none"></div>
    </div>
    <div>
        <div
            id="fullbleed-parallax-image"
            v-on:click="handleAdClick"
            class="ad-block__image"
            :style="{
                'background-image': 'url(' + state.imageUrl + ')'
            }"
        >
            <div class="ad-tag tag-text">
                <span>ANNONSE</span>
            </div>

            <img :src="state.imageUrl" loading="lazy" alt="" />
        </div>
    </div>
</template>
